import Cookies from 'js-cookie';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { IntlProvider } from 'react-intl';
import en from 'apexcharts/dist/locales/en.json';
import lv from 'apexcharts/dist/locales/lv.json';
import lt from 'apexcharts/dist/locales/lt.json';
import ru from 'apexcharts/dist/locales/ru.json';
import es from 'apexcharts/dist/locales/es.json';
import messagesEN from '../../assets/data/locales/en.json';
import messagesLT from '../../assets/data/locales/lt.json';
import messagesLV from '../../assets/data/locales/lv.json';
import messagesRU from '../../assets/data/locales/ru.json';
import messagesES from '../../assets/data/locales/es.json';
import { useAnalytics, analyticsEvents } from '@data/store/AnalyticsProvider';
import {
  SupportedLanguages,
  SupportedLocales,
  languageIsSupported,
  DEFAULT_LANGUAGE,
} from '@src/@data/utility/supportedLanguages';
import { Empty } from '@src/@data/utility/NotImplemented';
import { IntlErrorCode, OnErrorFn } from '@formatjs/intl';

export const allMessages: Record<SupportedLanguages, Record<string, string>> = {
  en: { ...messagesEN },
  lv: { ...messagesLV },
  lt: { ...messagesLT },
  ru: { ...messagesRU },
  es: { ...messagesES },
};

export const getApexchartLocaleOptions = (
  defaultLocale: SupportedLanguages
) => ({
  locales: [en, lt, ru, es, lv],
  defaultLocale,
});

interface ContextValueType {
  language: SupportedLanguages;
  switchLanguage?: (lang: SupportedLanguages) => void;
  timeZone?: string;
  setTimeZone: (timeZone?: string) => void;
}

const LANGUAGE_KEY = 'locale';

export const getLanguageCookie = () => {
  const lang = Cookies.get(LANGUAGE_KEY);
  if (lang && languageIsSupported(lang)) {
    return lang;
  }
  return DEFAULT_LANGUAGE;
};

export const InternationalizationContext = createContext<ContextValueType>({
  language: getLanguageCookie(),
  setTimeZone: Empty,
});

export const useInternationalization = () =>
  useContext(InternationalizationContext);

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const InternationalizationProvider: React.FC<Props> = ({ children }) => {
  // ** States
  const { track } = useAnalytics();
  const [language, setLanguage] = useState<SupportedLanguages>(
    getLanguageCookie()
  );
  const [timeZone, setTimeZone] = useState<string>();

  // ** Switches Language
  const switchLanguage = useCallback(
    (lang: SupportedLanguages) => {
      track(analyticsEvents.LANGUAGE_CHANGED, { language: lang });
      setLanguage(lang);
      Cookies.set(LANGUAGE_KEY, lang, { expires: 365 });
      window.location.reload();
    },
    [track]
  );

  const onIntlError: OnErrorFn = useCallback((error) => {
    if (error.code === IntlErrorCode.MISSING_TRANSLATION) {
      console.warn(
        error.message.replace(
          ', using id as fallback.',
          ', using default language as fallback'
        )
      );
    } else {
      console.error(error);
    }
  }, []);

  return (
    <InternationalizationContext.Provider
      value={{
        language,
        switchLanguage,
        timeZone,
        setTimeZone,
      }}
    >
      <IntlProvider
        locale={SupportedLocales[language]}
        messages={allMessages[language]}
        defaultLocale={SupportedLocales.en}
        timeZone={timeZone}
        onError={onIntlError}
      >
        {children}
      </IntlProvider>
    </InternationalizationContext.Provider>
  );
};
