import React from 'react';
import { X } from 'react-feather';
import Avatar from '../atoms/avatar';
import { useMessages } from '../../@data/utility/hooks/useMessages';

interface Props {
  title?: string;
  message?: unknown;
}

const ErrorToast: React.FC<Props> = ({ title, message }) => {
  const { messages } = useMessages();

  const getErrorMessage = (): string => {
    if (message instanceof Error && !!message.message) {
      return message.message;
    } else if (typeof message === 'string' && !!message) {
      return message;
    } else {
      return messages('error_message_general');
    }
  };

  return (
    <>
      <div className="toastify-header">
        <div className="title-wrapper">
          <Avatar size="sm" color="danger" icon={<X size={12} />} />
          <h6 className="toast-title">
            {title || messages('error_toast_title')}
          </h6>
        </div>
      </div>
      <div className="toastify-body" style={{ overflowWrap: 'break-word' }}>
        {getErrorMessage()}
      </div>
    </>
  );
};
export default ErrorToast;
