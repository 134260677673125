import { Empty } from '@src/@data/utility/NotImplemented';
import { createContext, useContext, useState } from 'react';
import themeConfig from '../configs/themeConfig';

export enum ContentWidth {
  full = 'full',
  boxed = 'boxed',
}

interface LayoutState {
  isRTL: boolean;
  menuCollapsed: boolean;
  menuHidden: boolean;
  contentWidth: ContentWidth;
  cookieBannerHidden: boolean;
}

interface LayoutActions {
  setIsRTL: (isRTL: boolean) => void;
  setMenuCollapsed: (menuCollapsed: boolean) => void;
  setMenuHidden: (menuHidden: boolean) => void;
  setContentWidth: (contentWidth: ContentWidth) => void;
  setCookieBannerHidden: (cookieBannerHidden: boolean) => void;
}

type LayoutContextType = LayoutState & LayoutActions;

// ** Returns Initial Menu Collapsed State
const initialMenuCollapsed = () => {
  /*const item = window.localStorage.getItem('menuCollapsed');
  //!** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed;*/
  return false;
};

const initialState: LayoutContextType = {
  isRTL: themeConfig.layout.isRTL,
  menuCollapsed: initialMenuCollapsed(),
  menuHidden: themeConfig.layout.menu.isHidden,
  contentWidth: themeConfig.layout.contentWidth as ContentWidth,
  cookieBannerHidden: true,
  setIsRTL: Empty,
  setMenuCollapsed: Empty,
  setMenuHidden: Empty,
  setContentWidth: Empty,
  setCookieBannerHidden: Empty,
};

const LayoutContext = createContext<LayoutContextType>(initialState);

export const useLayoutContext = () => useContext(LayoutContext);

export const LayoutProvider: React.FC = ({ children }) => {
  const [isRTL, setIsRTL] = useState(initialState.isRTL);
  const [menuCollapsed, setMenuCollapsed] = useState(
    initialState.menuCollapsed
  );
  const [menuHidden, setMenuHidden] = useState(initialState.menuHidden);
  const [contentWidth, setContentWidth] = useState(initialState.contentWidth);
  const [cookieBannerHidden, setCookieBannerHidden] = useState(true);

  return (
    <LayoutContext.Provider
      value={{
        isRTL,
        menuCollapsed,
        menuHidden,
        contentWidth,
        setIsRTL,
        setMenuCollapsed,
        setMenuHidden,
        setContentWidth,
        cookieBannerHidden,
        setCookieBannerHidden,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
