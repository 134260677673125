// ** React Imports
import { forwardRef, HTMLProps } from 'react';

// ** Third Party Components
import { Badge } from 'reactstrap';
import classNames from 'classnames';

export interface AvatarProps extends Omit<HTMLProps<HTMLDivElement>, 'size'> {
  imgClassName?: string;
  className?: string;
  src?: string;
  tag?: React.FC;
  badgeUp?: boolean;
  badgeDown?: boolean;
  content?: string;
  icon?: JSX.Element;
  contentStyles?: Record<string, string>;
  badgeText?: string | JSX.Element;
  img?: string;
  imgHeight?: string | number;
  imgWidth?: string | number;
  withImgBadge?: boolean;
  size?: 'sm' | 'lg' | 'xl';
  status?: 'online' | 'offline' | 'away' | 'busy';
  badgeColor?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'info'
    | 'warning'
    | 'dark'
    | 'light-primary'
    | 'light-secondary'
    | 'light-success'
    | 'light-danger'
    | 'light-info'
    | 'light-warning'
    | 'light-dark';
  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'info'
    | 'warning'
    | 'dark'
    | 'light-primary'
    | 'light-secondary'
    | 'light-success'
    | 'light-danger'
    | 'light-info'
    | 'light-warning'
    | 'light-dark';
  initials?: boolean;
}

const Avatar = forwardRef<HTMLDivElement, AvatarProps>((props, ref) => {
  // ** Props
  const {
    color,
    className,
    imgClassName,
    initials,
    size,
    badgeUp,
    content,
    icon,
    badgeColor = 'primary',
    badgeText,
    img,
    imgHeight,
    imgWidth,
    status,
    tag: Tag = 'div',
    contentStyles,
    withImgBadge,
    badgeDown,
    ...rest
  } = props;

  // ** Function to extract initials from content
  const getInitials = (str?: string) => {
    const results: string[] = [];
    const wordArray = (str || '').split(' ');
    wordArray.forEach((e) => {
      results.push(e[0]);
    });
    return results.join('');
  };

  return (
    <Tag
      className={classNames('avatar', className, {
        [`bg-${color}`]: color,
        [`avatar-${size}`]: size,
      })}
      ref={ref}
      {...rest}
    >
      {!img ? (
        <span
          className={classNames('avatar-content', {
            'position-relative': badgeUp,
          })}
          style={contentStyles}
        >
          {initials ? getInitials(content) : content}

          {icon ? icon : null}
          {!!badgeUp && (
            <Badge color={badgeColor} className="badge-sm badge-up" pill>
              {badgeText ? badgeText : '0'}
            </Badge>
          )}
          {!!badgeDown && (
            <Badge color={badgeColor} className="badge-sm badge-down p-0" pill>
              {badgeText ? badgeText : '0'}
            </Badge>
          )}
        </span>
      ) : (
        <>
          <img
            className={classNames(imgClassName, 'fit-cover')}
            src={img}
            alt="avatarImg"
            height={imgHeight && !size ? imgHeight : 32}
            width={imgWidth && !size ? imgWidth : 32}
          />
          {!!withImgBadge && (
            <Badge
              color={badgeColor}
              className={classNames(
                badgeUp ? 'badge-up' : 'badge-down',
                'badge-sm p-0'
              )}
              pill
            >
              {badgeText ? badgeText : ''}
            </Badge>
          )}
        </>
      )}
      {status ? (
        <span
          className={classNames({
            [`avatar-status-${status}`]: status,
            [`avatar-status-${size}`]: size,
          })}
        />
      ) : null}
    </Tag>
  );
});

export default Avatar;
