import { allMessages } from '@src/@data/store/InternationalizationProvider';
import { DEFAULT_LANGUAGE } from '@src/@data/utility/supportedLanguages';
import { useCallback } from 'react';
import { IntlShape, useIntl } from 'react-intl';

type PrimitiveType = string | number | boolean | null | undefined | Date;
type FormatXMLElementFn<T, R = string | T | Array<string | T>> = (
  parts: Array<string | T>
) => R;

// type overloads
function getMessage(
  intl: IntlShape,
  key: string,
  values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>
): string;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getMessage<T = any, R = T>(
  intl: IntlShape,
  key: string,
  values?: Record<string, PrimitiveType | T | FormatXMLElementFn<T, R>>
): R;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getMessage<T = any, R = T>(
  intl: IntlShape,
  key: string,
  data?: Record<string, PrimitiveType | T | FormatXMLElementFn<T, R>>
) {
  const message = intl.formatMessage({ id: key }, data);

  if (message === key) {
    let defaultMessage = allMessages[DEFAULT_LANGUAGE][key] || '';

    if (
      !defaultMessage &&
      process.env.REACT_APP_SHOW_MISSING_INTL_KEYS === 'true'
    ) {
      return key;
    }

    if (!data) {
      return defaultMessage;
    }

    Object.keys(data).forEach((key) => {
      // Only works for primitive types
      defaultMessage = defaultMessage.replaceAll(`{${key}}`, `${data[key]}`);
    });
    return defaultMessage;
  }

  return message;
}

export const useMessages = () => {
  const intl = useIntl();

  const messages = useCallback(
    function <T = string, R = T>(
      key: string,
      data?: Record<string, PrimitiveType | T | FormatXMLElementFn<T, R>>
    ) {
      return getMessage(intl, key, data);
    },
    [intl]
  );

  return { messages };
};
