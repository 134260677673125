import Rollbar from 'rollbar';
import { toast, ToastOptions } from 'react-toastify';
import ErrorToast from '@src/components/molecules/ErrorToast';
import axios, { AxiosError } from 'axios';
import { User } from '../types/user';
import { hasCookieConsent } from '@src/components/organisms/cookie-banner/CookieBanner';

export const isAxiosError = (error: unknown): error is AxiosError => {
  return axios.isAxiosError(error);
};

export const isError = (error: unknown): error is AxiosError | Error => {
  return isAxiosError(error) || error instanceof Error;
};

const rollbarConfig: Rollbar.Configuration = {
  enabled: process.env.REACT_APP_BOOKLA_ENV === 'production',
  accessToken: process.env.REACT_APP_RUNTIME_ERROR_MONITORING_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.REACT_APP_BOOKLA_ENV,
  },
};

interface LoggerQueueErrorOnceItem {
  action: 'errorOnce';
  params: [unknown, string];
}
interface LoggerQueueErrorItem {
  action: 'error';
  params: [unknown];
}
interface LoggerQueueWarningItem {
  action: 'warning';
  params: [Error];
}
interface LoggerQueueInfoItem {
  action: 'info';
  params: [string, string | undefined];
}
interface LoggerQueueIdentifyUserItem {
  action: 'identifyUser';
  params: [User];
}
type LoggerQueueItem =
  | { action: 'init' }
  | LoggerQueueErrorItem
  | LoggerQueueErrorOnceItem
  | LoggerQueueWarningItem
  | LoggerQueueInfoItem
  | LoggerQueueIdentifyUserItem;

class Logger {
  private rollbar?: Rollbar;
  private oneTimeErrors: Record<string, string[]> = {};
  private queue: LoggerQueueItem[] = [];
  private isProcessingQueue = false;

  constructor() {
    this.init();
  }

  private processQueueItem = (item: LoggerQueueItem) => {
    switch (item.action) {
      case 'init':
        this._init();
        break;
      case 'error':
        this._error(...item.params);
        break;
      case 'errorOnce':
        this._errorOnce(...item.params);
        break;
      case 'warning':
        this._warning(...item.params);
        break;
      case 'info':
        this._info(...item.params);
        break;
      case 'identifyUser':
        this._identifyUser(...item.params);
        break;
    }
  };

  private processQueue = () => {
    this.isProcessingQueue = true;
    this.queue.forEach((item) => {
      this.processQueueItem(item);
    });
    this.queue = [];
    this.isProcessingQueue = false;
  };

  private resetQueue = () => {
    this.queue = this.queue.filter((item) => item.action === 'init');
  };

  private handleAction = (action: LoggerQueueItem, cb: () => void) => {
    const hasConsent = hasCookieConsent('isStatisticsSelected');
    if (hasConsent === undefined || this.isProcessingQueue) {
      this.queue.push(action);
      return;
    }
    if (!hasConsent) {
      this.resetQueue();
      return;
    }
    if (this.queue.length) {
      this.processQueue();
    }

    cb();
  };

  private _init() {
    this.rollbar = new Rollbar(rollbarConfig);
  }

  private init() {
    this.handleAction({ action: 'init' }, () => {
      this._init();
    });
  }

  private _error(err: unknown) {
    if (process.env.REACT_APP_BOOKLA_ENV !== 'production') {
      console.error(err);
    }

    this.rollbar?.error(err as Error);
  }

  public error(err: unknown) {
    this.handleAction({ action: 'error', params: [err] }, () => {
      this._error(err);
    });
  }

  private _errorOnce(err: unknown, key: string) {
    if (!this.oneTimeErrors[key]) {
      this.oneTimeErrors[key] = [];
    }
    const message = isError(err)
      ? err.message
      : typeof err === 'string'
      ? err
      : undefined;
    if (!message || !this.oneTimeErrors[key].includes(message)) {
      if (message) {
        this.oneTimeErrors[key].push(message);
      }

      this.error(err);
    }
  }

  public errorOnce(err: unknown, key: string) {
    this.handleAction({ action: 'errorOnce', params: [err, key] }, () => {
      this._errorOnce(err, key);
    });
  }

  private _warning(err: Error) {
    if (process.env.REACT_APP_BOOKLA_ENV !== 'production') {
      console.warn(err);
    }

    this.rollbar?.warning(err);
  }

  public warning(err: Error) {
    this.handleAction({ action: 'warning', params: [err] }, () => {
      this._warning(err);
    });
  }

  private _info(message: string, key?: string) {
    if (process.env.REACT_APP_BOOKLA_ENV !== 'production') {
      const prefix = !!key ? `${key}: ` : '';
      console.info(`${prefix}${message}`);
    }

    this.rollbar?.captureEvent(
      {
        key,
        message,
      },
      'info'
    );
  }

  public info(message: string, key?: string) {
    this.handleAction({ action: 'info', params: [message, key] }, () => {
      this._info(message, key);
    });
  }

  private _identifyUser(user: User) {
    this.rollbar?.configure({
      payload: {
        person: {
          id: user.id,
        },
      },
    });
  }

  public identifyUser(user: User) {
    this.handleAction({ action: 'identifyUser', params: [user] }, () => {
      this._identifyUser(user);
    });
  }
}

export const logger = new Logger();

export const logOnError = (error?: unknown) => {
  logger.error(error);
};

export const toastOnError = (error?: unknown, toastOptions?: ToastOptions) => {
  logger.error(error);
  toast.error(<ErrorToast message={error} />, {
    hideProgressBar: true,
    position: toast.POSITION.TOP_CENTER,
    bodyStyle: { maxWidth: '95%' },
    ...toastOptions,
  });
};
