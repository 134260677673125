// ** React Imports
import { useEffect, useState, createContext, useContext } from 'react';

interface ThemeContext {
  colors: Partial<{
    primary: {
      light: string;
      main: string;
    };
    secondary: {
      light: string;
      main: string;
    };
    success: {
      light: string;
      main: string;
    };
    danger: {
      light: string;
      main: string;
    };
    warning: {
      light: string;
      main: string;
    };
    info: {
      light: string;
      main: string;
    };
    dark: {
      light: string;
      main: string;
    };
  }>;
}

// ** Create Context
const themeContext = createContext<ThemeContext>({
  colors: {},
});

export const useThemeContext = () => useContext(themeContext);

export const ThemeProvider: React.FC = ({ children }) => {
  // ** State
  const [colors, setColors] = useState<ThemeContext['colors']>({});

  //** ComponentDidMount
  useEffect(() => {
    //** Get variable value
    const getHex = (color: string) =>
      window.getComputedStyle(document.body).getPropertyValue(color).trim();

    //** Colors obj
    const obj = {
      primary: {
        light: getHex('--primary').concat('1a'),
        main: getHex('--primary'),
      },
      secondary: {
        light: getHex('--secondary').concat('1a'),
        main: getHex('--secondary'),
      },
      success: {
        light: getHex('--success').concat('1a'),
        main: getHex('--success'),
      },
      danger: {
        light: getHex('--danger').concat('1a'),
        main: getHex('--danger'),
      },
      warning: {
        light: getHex('--warning').concat('1a'),
        main: getHex('--warning'),
      },
      info: {
        light: getHex('--info').concat('1a'),
        main: getHex('--info'),
      },
      dark: {
        light: getHex('--dark').concat('1a'),
        main: getHex('--dark'),
      },
    };

    setColors({ ...obj });
  }, []);

  return (
    <themeContext.Provider value={{ colors }}>{children}</themeContext.Provider>
  );
};
