export enum SupportedLanguages {
  en = 'en',
  lv = 'lv',
  ru = 'ru',
  lt = 'lt',
  es = 'es',
}
export enum SupportedLocales {
  en = 'en-GB',
  lv = 'lv-LV',
  ru = 'ru-RU',
  lt = 'lt-LT',
  es = 'es-ES',
}
export const DEFAULT_LANGUAGE: SupportedLanguages = SupportedLanguages.en;
export const SUPPORTED_LANGUAGES: SupportedLanguages[] =
  Object.values(SupportedLanguages);

export const languageIsSupported = (
  lang: string
): lang is SupportedLanguages => {
  return SUPPORTED_LANGUAGES.includes(lang as SupportedLanguages);
};

export const getLanguage = (locale: SupportedLocales) =>
  locale.split('-')[0] as SupportedLanguages;

export interface SupportedLanguage {
  id: SupportedLanguages;
  title: string;
  default: boolean;
  position: number;
}

export const SupportedCompanyLanguages: Record<string, SupportedLanguage> = {
  en: {
    id: SupportedLanguages.en,
    title: 'language_english',
    default: true,
    position: 0,
  },
  lv: {
    id: SupportedLanguages.lv,
    title: 'language_latvian',
    default: false,
    position: 1,
  },
  ru: {
    id: SupportedLanguages.ru,
    title: 'language_russian',
    default: false,
    position: 2,
  },
  lt: {
    id: SupportedLanguages.lt,
    title: 'language_lithuanian',
    default: false,
    position: 3,
  },
  es: {
    id: SupportedLanguages.es,
    title: 'language_spanish',
    default: false,
    position: 4,
  },
};

export const SupportedPortalLanguages: Record<string, SupportedLanguage> = {
  en: {
    id: SupportedLanguages.en,
    title: 'language_english',
    default: true,
    position: 0,
  },
  lv: {
    id: SupportedLanguages.lv,
    title: 'language_latvian',
    default: false,
    position: 1,
  },
  ru: {
    id: SupportedLanguages.ru,
    title: 'language_russian',
    default: false,
    position: 2,
  },
  es: {
    id: SupportedLanguages.es,
    title: 'language_spanish',
    default: false,
    position: 3,
  },
};

export const defaultCompanyLanguage = (): SupportedLanguage => {
  return SupportedCompanyLanguages['en'];
};
