import { useLayoutContext } from '@src/@data/store/LayoutProvider';
import { logOnError } from '@src/@data/utility/errors';
import { getPrivacyPolicyLink } from '@src/@data/utility/externalLinks';
import { useMessages } from '@src/@data/utility/hooks/useMessages';
import { useInternationalization } from '@src/@data/store/InternationalizationProvider';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  CustomInput,
  Label,
  ListGroup,
  ListGroupItem,
  Toast,
  ToastBody,
  ToastHeader,
} from 'reactstrap';

const cookieName = 'portalCookieConsent';

interface CookieBannerSettings {
  isStatisticsSelected: boolean;
}

const defaultSettings: CookieBannerSettings = {
  isStatisticsSelected: false,
};

export const hasCookieConsent = (
  key: keyof CookieBannerSettings
): boolean | undefined => {
  try {
    const cookie = Cookies.get(cookieName);
    const parsedCookieSettings = cookie
      ? (JSON.parse(cookie) as CookieBannerSettings)
      : undefined;
    return parsedCookieSettings?.[key];
  } catch (error) {
    logOnError(error);
    return false;
  }
};

export const CookieBanner: React.FC = () => {
  const { cookieBannerHidden, setCookieBannerHidden } = useLayoutContext();
  const [settings, setSettings] =
    useState<CookieBannerSettings>(defaultSettings);
  const { messages } = useMessages();
  const { language } = useInternationalization();

  const updateSettings = useCallback(
    (options: Partial<CookieBannerSettings>) => {
      setSettings((previous) => ({ ...previous, ...options }));
    },
    []
  );

  const save = useCallback(
    (options: CookieBannerSettings) => {
      Cookies.set(cookieName, JSON.stringify(options), { expires: 365 });
      setSettings(options);
      setCookieBannerHidden(true);
    },
    [setCookieBannerHidden]
  );

  useEffect(() => {
    try {
      const cookie = Cookies.get(cookieName);
      const settings = cookie
        ? (JSON.parse(cookie) as CookieBannerSettings)
        : undefined;

      setSettings({ ...defaultSettings, ...settings });
      if (!settings) {
        setCookieBannerHidden(false);
      }
    } catch (error) {
      logOnError(error);
    }
  }, [setCookieBannerHidden]);

  if (cookieBannerHidden) {
    return null;
  }

  return (
    <div
      className="position-fixed bottom-0 left-0 right-0 top-0 overflow-hidden pointer-events-all"
      style={{ backgroundColor: 'rgba(0,0,0,0.3)', zIndex: 10000 }}
    >
      <Toast
        className="position-fixed bottom-0 left-0 right-0 w-100 mb-0 bg-white"
        style={{ maxWidth: '100%' }}
      >
        <ToastHeader className="px-1 pt-1">
          <h4 className="mb-0">{messages('cookie_banner_title')}</h4>
        </ToastHeader>
        <ToastBody>
          <p>
            {messages('cookie_banner_description', {
              privacyPolicyLink: (
                <a
                  href={getPrivacyPolicyLink(language)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {messages('policies_privacy_policy')}
                </a>
              ),
            })}
          </p>
          <ListGroup>
            <ListGroupItem>
              <Label className="d-flex align-items-center">
                <CustomInput
                  className="mr-50"
                  type="checkbox"
                  id="cookie-banner-checkbox-necessary"
                  checked={true}
                  disabled
                />
                <span>
                  <b>{messages('cookie_banner_type_necessary')}</b>
                </span>
              </Label>
              <p>{messages('cookie_banner_type_necessary_description')}</p>
            </ListGroupItem>
            <ListGroupItem>
              <Label className="d-flex align-items-center">
                <CustomInput
                  className="mr-50"
                  type="checkbox"
                  id="cookie-banner-checkbox-stats"
                  checked={settings.isStatisticsSelected}
                  onChange={() =>
                    updateSettings({
                      isStatisticsSelected: !settings.isStatisticsSelected,
                    })
                  }
                />
                <span>
                  <b>{messages('cookie_banner_type_statistics')}</b>
                </span>
              </Label>
              <p>{messages('cookie_banner_type_statistics_description')}</p>
            </ListGroupItem>
          </ListGroup>
          <div className="d-flex align-items-center mt-2 justify-content-end">
            <Button
              size="sm"
              color="danger"
              outline
              className="mr-1"
              onClick={() => save({ isStatisticsSelected: false })}
            >
              {messages('cookie_banner_button_reject_all')}
            </Button>
            <Button
              size="sm"
              color="primary"
              outline
              className="mr-1"
              onClick={() => save(settings)}
            >
              {messages('cookie_banner_button_allow_selected')}
            </Button>
            <Button
              size="sm"
              color="primary"
              onClick={() =>
                save({
                  isStatisticsSelected: true,
                })
              }
            >
              {messages('cookie_banner_button_allow_all')}
            </Button>
          </div>
        </ToastBody>
      </Toast>
    </div>
  );
};
