export const analyticsEvents = {
  SIGN_UP: 'sign_up',
  SIGN_IN: 'sign_in',
  LOGOUT: 'logout',
  LANGUAGE_CHANGED: 'language_changed',
  PAGE_USER_DETAILS: 'page_user_details',
  PAGE_SIGN_IN: 'page_sign_in',
  PAGE_SIGN_UP: 'page_sign_up',
  COMPANY_CONTACTS_UPDATED: 'company_contacts_updated',
  PAGE_COMPANY_DETAILS: 'page_company_details',
  COMPANY_DETAILS_UPDATED: 'company_details_updated',
  COMPANY_IMAGE_UPDATED: 'company_image_updated',
  COMPANY_TRANSLATIONS_UPDATED: 'company_translations_updated',
  COMPANY_ENABLED_CHANGED: 'company_enabled_changed',
  PROMO_CODE_CREATED: 'promo_code_created',
  PROMO_CODE_DELETED: 'promo_code_deleted',
  PAGE_PROMO_CODES: 'page_promo_codes',
  PAGE_PROMO_CODE_DETAILS: 'page_promo_code_details',
  INVOICE_DOWNLOADED: 'invoice_downloaded',
  PAGE_INVOICES: 'page_invoices',
  SERVICE_SELECTED: 'service_selected',
  SERVICE_GROUP_SELECTED: 'service_group_selected',
  PAGE_SERVICES: 'page_services',
  PAGE_CREATE_SERVICE: 'page_create_service',
  PAGE_SERVICE_GROUP_CONTENT: 'page_service_group_content',
  SERVICE_GROUP_CREATED: 'service_group_created',
  PAGE_SERVICE_GROUP_CREATE: 'page_create_service_group',
  SERVICE_GROUP_TRANSLATIONS_UPDATED: 'service_group_translations_updated',
  SERVICE_CREATE_SELECTED: 'service_create_selected',
  SERVICE_GROUP_CREATE_SELECTED: 'service_group_create_selected',
  SERVICE_ENABLED_CHANGED: 'service_enabled_changed',
  PAGE_SERVICE_CONTENT: 'page_service_content',
  PAGE_SERVICE_CALENDARS: 'page_service_calendars',
  PAGE_SERVICE_CLIENT_GROUPS: 'page_service_client_groups',
  PAGE_SERVICE_FIELDS: 'page_service_fields',
  SERVICE_CREATED: 'service_created',
  SERVICE_CLONED: 'service_cloned',
  SERVICE_DELETED: 'service_deleted',
  SERVICE_DETAILS_UPDATED: 'service_details_updated',
  SERVICE_IMAGE_UPDATED: 'service_image_updated',
  SERVICE_TRANSLATIONS_UPDATED: 'service_translations_updated',
  SERVICE_CALENDARS_UPDATED: 'service_calendars_updated',
  SERVICE_CLIENT_GROUPS_UPDATED: 'service_client_groups_updated',
  SERVICE_FIELDS_UPDATED: 'service_fields_updated',
  PAGE_SERVICE_SETTINGS: 'page_service_settings',
  SERVICE_SETTINGS_COMMON_UPDATED: 'service_settings_common_updated',
  SERVICE_SETTINGS_ADVANCED_UPDATED: 'service_settings_advanced_updated',
  SERVICE_SETTINGS_TIME_UPDATED: 'service_settings_time_updated',
  SERVICE_SETTINGS_SLOT_UPDATED: 'service_settings_slot_updated',
  PAGE_COMPANY_STATISTICS: 'page_statistics',
  PAGE_TRANSACTIONS_STATISTICS: 'page_transactions_statistics',
  PAGE_CALENDAR_STATISTICS: 'page_calendar_statistics',
  PAGE_CREATE_COMPANY: 'page_create_company',
  PAGE_INTEGRATIONS_WEB_WIDGET: 'page_integrations_web_widget',
  PRODUCT_CREATE_SELECTED: 'product_create_selected',
  PAGE_PRODUCTS: 'page_products',
  // Calendars
  PAGE_CALENDARS: 'page_calendars',
  PAGE_CALENDAR_CREATE: 'page_create_calendar',
  PAGE_CALENDAR_CONTENT: 'page_calendar_content',
  PAGE_CALENDAR_SERVICES: 'page_calendar_services',
  CALENDAR_SELECTED: 'calendar_selected',
  CALENDAR_CREATED: 'calendar_created',
  CALENDAR_CLONED: 'calendar_cloned',
  CALENDAR_CREATE_SELECTED: 'calendar_create_selected',
  CALENDAR_ENABLED_CHANGED: 'calendar_enabled_changed',
  CALENDAR_IMAGE_UPDATED: 'calendar_image_updated',
  CALENDAR_DETAILS_UPDATED: 'calendar_details_updated',
  CALENDAR_DELETED: 'calendar_deleted',
  CALENDAR_WORK_HOURS_UPDATED: 'calendar_work_hours_updated',
  CALENDAR_SETTINGS_UPDATED: 'calendar_settings_updated',
  CALENDAR_SERVICES_UPDATED: 'calendar_services_updated',
  COMPANY_CREATED: 'company_signup',
  // Venues
  VENUE_SELECTED: 'venue_selected',
  VENUE_CREATE_SELECTED: 'venue_create_selected',
  VENUE_ENABLED_CHANGED: 'venue_enabled_changed',
  VENUE_DELETED: 'venue_deleted',
  VENUE_CREATED: 'venue_created',
  PAGE_VENUE_CREATE: 'page_create_venue',
  PAGE_VENUES: 'page_venues',
  VENUE_DETAILS_UPDATED: 'venue_details_updated',
  // Create company wizard
  PAGE_CREATE_COMPANY_WIZARD: 'page_create_company_wizard',
  CREATE_COMPANY_WIZARD_COMPLETED: 'create_company_wizard_completed',
  CREATE_COMPANY_WIZARD_CATEGORY_STEP: 'create_company_wizard_category_step',
  CREATE_COMPANY_WIZARD_COMPANY_TYPE_STEP:
    'create_company_wizard_company_type_step',
  CREATE_COMPANY_WIZARD_TEAM_SIZE_STEP: 'create_company_wizard_team_size_step',
  CREATE_COMPANY_WIZARD_LOCATION_STEP: 'create_company_wizard_location_step',
  CREATE_COMPANY_WIZARD_WORKING_HOURS_STEP:
    'create_company_wizard_working_hours_step',
  CREATE_COMPANY_WIZARD_INTEGRATIONS_STEP:
    'create_company_wizard_integrations_step',
  CREATE_COMPANY_WIZARD_BUSINESS_MOBILE_APP_STEP:
    'create_company_wizard_business_mobile_app_step',
  CREATE_COMPANY_WIZARD_REFERENCE_STEP: 'create_company_wizard_reference_step',
  CREATE_COMPANY_WIZARD_SUCCESS_STEP: 'create_company_wizard_success_step',
  CREATE_COMPANY_WIZARD_POPULAR_CATEGORIES_STEP:
    'create_company_wizard_popular_categories_step',
  CREATE_COMPANY_WIZARD_AUTOMATION_STEP:
    'create_company_wizard_automation_step',
  CREATE_COMPANY_WIZARD_TURNOVER_STEP: 'create_company_wizard_turnover_step',
  // User roles
  PAGE_USER_ROLES: 'page_user_roles',
  USER_ROLES_CREATE: 'page_user_roles_create',
  USER_ROLE_CREATED: 'user_role_created',
  USER_ROLE_EDITED: 'user_role_edited',
  // Campaigns
  PAGE_CAMPAIGNS: 'page_campaigns',
  CAMPAIGN_CREATE_SELECTED: 'campaign_create_selected',
  // Gift cards
  PAGE_GIFT_CARDS: 'page_gift_cards',
  GIFT_CARD_CREATE_SELECTED: 'gift_card_create_selected',
  // Notifications
  PAGE_NOTIFICATIONS: 'page_notifications',
  // Activity logs
  PAGE_ACTIVITY_LOGS: 'page_activity_logs',
} as const;
