import { logOnError } from '@src/@data/utility/errors';
import mixpanel from 'mixpanel-browser';
import { hasCookieConsent } from '@appcomponents/organisms/cookie-banner/CookieBanner';

export interface AnalyticProperties {
  [key: string]: unknown;
}

interface TrackingQueueTrackItem {
  action: 'track';
  params: [string, AnalyticProperties | undefined];
}

interface TrackingQueueIdentifyItem {
  action: 'identify';
  params: [string];
}

type TrackingQueueItem =
  | { action: 'init' | 'reset' }
  | TrackingQueueTrackItem
  | TrackingQueueIdentifyItem;

export class AnalyticsProvider {
  private static _instance: AnalyticsProvider;
  private queue: TrackingQueueItem[] = [];
  private isProcessingQueue = false;

  static getInstance(): AnalyticsProvider {
    if (!this._instance) {
      this._instance = new AnalyticsProvider();
    }
    return this._instance;
  }

  private constructor() {
    this.init();
  }

  private processQueueItem = (item: TrackingQueueItem) => {
    switch (item.action) {
      case 'init':
        this._init();
        break;
      case 'track':
        this._track(...item.params);
        break;
      case 'identify':
        this._identify(...item.params);
        break;
      case 'reset':
        this._reset();
        break;
    }
  };

  private processQueue = () => {
    this.isProcessingQueue = true;
    this.queue.forEach((item) => {
      this.processQueueItem(item);
    });
    this.queue = [];
    this.isProcessingQueue = false;
  };

  private resetQueue = () => {
    this.queue = this.queue.filter((item) => item.action === 'init');
  };

  private handleAction = (action: TrackingQueueItem, cb: () => void) => {
    const hasConsent = hasCookieConsent('isStatisticsSelected');
    if (hasConsent === undefined || this.isProcessingQueue) {
      this.queue.push(action);
      return;
    }
    if (!hasConsent) {
      this.resetQueue();
      return;
    }
    if (this.queue.length) {
      this.processQueue();
    }

    cb();
  };

  private _init = () => {
    try {
      const key = process.env.REACT_APP_MIXPANEL_KEY;
      if (!key) {
        logOnError('Analytics key not found');
      } else {
        mixpanel.init(key, {
          debug: process.env.REACT_APP_BOOKLA_ENV !== 'production',
        });
      }
    } catch (e) {
      logOnError(`Failed to init mixpanel: ${e}`);
    }
  };

  private init = () => {
    this.handleAction({ action: 'init' }, () => {
      this._init();
    });
  };

  private _track = (event: string, properties?: AnalyticProperties) => {
    try {
      mixpanel.track(event, properties);
    } catch (e) {
      logOnError(`Failed to track: ${e}`);
    }
  };

  public track = (event: string, properties?: AnalyticProperties) => {
    this.handleAction({ action: 'track', params: [event, properties] }, () => {
      this._track(event, properties);
    });
  };

  private _identify = (userId: string) => {
    try {
      mixpanel.identify(userId);
    } catch (e) {
      logOnError(`Failed to identify user: ${e}`);
    }
  };

  public identify = (userId: string) => {
    this.handleAction({ action: 'identify', params: [userId] }, () => {
      this._identify(userId);
    });
  };

  private _reset = () => {
    try {
      mixpanel.reset();
    } catch (e) {
      logOnError(`Failed to reset mixpanel: ${e}`);
    }
  };

  public reset = () => {
    this.handleAction({ action: 'reset' }, () => {
      this._reset();
    });
  };
}

export const useAnalytics = (): AnalyticsProvider => {
  return AnalyticsProvider.getInstance();
};
