// ** React Imports
import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';

// ** Toast & ThemeColors Context
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from './@data/store/ThemeProvider';

// ** Spinner (Splash Screen)
import { AppSpinner } from './components/atoms/AppSpinner';

// ** PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss';

// ** Data tables
import '@styles/react/libs/tables/react-dataTable-component.scss';

// ** Core styles
import './assets/fonts/feather/iconfont.css';
import './assets/scss/style.scss';

// ** Service Worker
import * as serviceWorker from './serviceWorker';

import { InternationalizationProvider } from './@data/store/InternationalizationProvider';
import { LayoutProvider } from './@data/store/LayoutProvider';

// ** Lazy load app
const LazyApp = lazy(() => import('./App'));

// End axios

ReactDOM.render(
  <Suspense fallback={<AppSpinner />}>
    <LayoutProvider>
      <ThemeProvider>
        <InternationalizationProvider>
          <LazyApp />
          <ToastContainer newestOnTop />
        </InternationalizationProvider>
      </ThemeProvider>
    </LayoutProvider>
  </Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
